import { authDataKey, refreshDataKey } from '../constants/auth';
import { endpoints } from '../api/endpoints';
import { apiHost } from '../constants/api';

export interface IAuthData {
  isLoggedIn: boolean;
  expired: number;
  accessToken: string;
}

export interface IRefreshData {
  isLoggedIn: boolean;
  expired: number;
  refreshToken: string;
}

function makeUrl(path: string): string {
  //This will grab the URL so we can call it later to make api calls
  const baseUrl = (window as any).API_HOST;
  return baseUrl+path;
}

export function isUserLoggedIn(storage: Storage = window.sessionStorage) {
  const authData = storage.getItem(authDataKey);
  if (authData) {
    const parsedAuthData: IAuthData = JSON.parse(authData);
    const currentTime = new Date().getTime();
    if (parsedAuthData.isLoggedIn && parsedAuthData.expired > currentTime) {
      return true;
    }
  }
  return false;
}

// expirationTimeInMs - default value 30 minutes: 30 * 60 * 1000 = 1800000
export function authUserInStorage(
  accessToken: string,
  expirationTimeInMs: number = 1800000,
  // expirationTimeInMs: number = 60000, // 1 minute
  storage: Storage = window.sessionStorage
) {
  const currentTime = new Date().getTime();
  const expirationDateTime = currentTime + expirationTimeInMs;
  addAuthDataToStorage({ isLoggedIn: true, expired: expirationDateTime, accessToken }, storage);
}

// expirationTimeInMs - default value 4 hours: 4 * 60 * 60 * 1000 = 14400000
export function addRefreshToken(
  refreshToken: string,
  expirationTimeInMs: number = 14400000,
  // expirationTimeInMs: number = 360000, // 6 minutes
  storage: Storage = window.localStorage
  ) {
    const currentTime = new Date().getTime();
    const expirationDateTime = currentTime + expirationTimeInMs;
    addRefreshDataToStorage({ isLoggedIn: true, expired: expirationDateTime, refreshToken:refreshToken }, storage);
  }

export function getAccessToken(storage: Storage = window.sessionStorage): string {
  const authData = storage.getItem(authDataKey);
  if (authData) {
    const parsedAuthData: IAuthData = JSON.parse(authData);
    if (parsedAuthData.isLoggedIn) {
      return parsedAuthData.accessToken;
    }
  }
  return '';
}

export function removeAuthDataFromStorage(storage: Storage = window.sessionStorage) {
  storage.removeItem(authDataKey);
}

export function removeRefreshDataFromStorage(storage: Storage = window.localStorage){
  storage.removeItem(refreshDataKey);
}

export function addAuthDataToStorage(
  authData: IAuthData,
  storage: Storage = window.sessionStorage
) {
  storage.setItem(authDataKey, JSON.stringify(authData));
}

export function addRefreshDataToStorage(
  refreshData: IRefreshData,
  storage: Storage = window.localStorage
) {
  storage.setItem(refreshDataKey, JSON.stringify(refreshData));
}

export function checkAuthExpire(
  /* -------------------------------------------------------------------------------------------------------------------
  |  This function will just check the SESSION STORAGE and return the expire time of the Session Token in a int format |
  |  The reason for the retrun 0's is because if they are not there then "There is a change it could be empty"         |
  ------------------------------------------------------------------------------------------------------------------- */
  seshStorage: Storage = window.sessionStorage
) {
  const authData = seshStorage.getItem(authDataKey);
  if (authData) {
    const parsedAuthData: IAuthData = JSON.parse(authData);
    if (parsedAuthData.isLoggedIn){
      return parsedAuthData.expired;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}

export function checkRefreshExpire(
  /* -----------------------------------------------------------------------------------------------------------------
  |  This function will just check the LOCAL STORAGE and return the expire time of the Refresh Token in a int format |
  |  The reason for the retrun 0's is because if they are not there then "There is a change it could be empty"       |
  ------------------------------------------------------------------------------------------------------------------*/
  locStorage: Storage = window.localStorage
) {
  const refreshData = locStorage.getItem(refreshDataKey);
  if (refreshData) {
    const parsedRefreshData: IAuthData = JSON.parse(refreshData);
    if (parsedRefreshData.isLoggedIn){
      return parsedRefreshData.expired;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}

export async function checkAndRenewAccessToken(
  /* ----------------------------------------------------------------------------------------------
  |  The goal of this function will be to check if the expire times for the functions has passed. |
  |  If it has then we will make a new token using the /user/refresh/ endpoint                    |
  -----------------------------------------------------------------------------------------------*/
  locStorage: Storage = window.localStorage,
) {
  const authExpire = checkAuthExpire();
  const refreshExpire = checkRefreshExpire();
  const currentTime = new Date().getTime();
  const refreshData = locStorage.getItem(refreshDataKey);
  if (authExpire < currentTime){
    if (refreshExpire > currentTime){
      if(refreshData){
        const parsedRefreshData: IRefreshData = JSON.parse(refreshData);  //This is parsing the refresh token so you can call it with "parsedRefreshData.refreshToken"
        /*-----------------------------------------------------------------------
        | This whole next part is all about calling the /user/refresh/ endpoint |
        | This will result in us getting back a new access token                |
        -----------------------------------------------------------------------*/
        const url = new URL(apiHost + endpoints.userRefresh);
        await fetch(url.toString(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            refreshToken: parsedRefreshData.refreshToken
          })
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error calling the backend! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          authUserInStorage(data.access_token)
        })
        .catch (error => {
          console.error('Error: ', error);
        });
      }
      return 1;
    }
    else {
      removeRefreshDataFromStorage(); // Session timeout. We don't want to leak our refresh token data
      return 0;
    }
  } else {
    // Everything is okay, do nothing.
    return 0;
  }
}