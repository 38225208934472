"use client";

import Layout from '../components/layout';
import { useNavigate } from 'react-router-dom';
import React, { Suspense, useState, useEffect } from "react";
import { Pane, Button, TextInputField, Heading, majorScale, toaster } from 'evergreen-ui';
import { changePassword } from '../api/shared';
import './changePassword.css';

const ChangePassword: React.FC = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();
  
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!currentPassword || !newPassword || !confirmPassword) {
            toaster.danger('All fields are required.');
            return;
        }

        if (newPassword !== confirmPassword) {
            toaster.danger('Passwords do not match.');
            return;
        }
  
        if (newPassword.length < 8) {
            toaster.danger('Password must be at least 8 characters long.');
            return;
        }
  
        // Example success handling, replace with actual password update logic
        try {
            await changePassword(currentPassword, newPassword);
            toaster.success('Password successfully changed!');
            // Redirect to login page after a short delay
            setTimeout(() => {
                navigate('/login');
            }, 2000); // Adjust delay as needed
        } catch (error) {
            toaster.danger('Failed to change password. Please try again.');
            return;
        }
    };
  
    return (
        <Pane className="change-password-background">
            <Pane
                className="custom-form"
                elevation={1}
                width={400}
                padding={majorScale(3)}
                borderRadius={8}
            >
                <Heading size={600} marginBottom={majorScale(2)}>
                    Change Password
                </Heading>
                <form onSubmit={handleSubmit}>
                    <TextInputField
                        label={<span>Current Password<span className="red-asterisk">*</span></span>}
                        type="password"
                        value={currentPassword}
                        onChange={(e: any) => setCurrentPassword(e.target.value)}
                    />
                    <TextInputField
                        label={<span>New Password<span className="red-asterisk">*</span></span>}
                        type="password"
                        value={newPassword}
                        onChange={(e: any) => setNewPassword(e.target.value)}
                    />
                    <TextInputField
                        label={<span>Confirm New Password<span className="red-asterisk">*</span></span>}
                        type="password"
                        value={confirmPassword}
                        onChange={(e: any) => setConfirmPassword(e.target.value)}
                    />
                    <button className="custom-button" type="submit">Change Password</button>
                </form>
            </Pane>
        </Pane>
    );
};
  
export default ChangePassword;