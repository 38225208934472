export const endpoints = {
    apiRoot: '/',
    status: '/status/all',
    accountsRoot: '/svcs/v1/account/',
    accountsImport: '/svcs/v1/account/import',
    deviceCount: '/svcs/v2/account/count',
    invitationRoot: '/svcs/v1/invitation/',
    groupRoot: '/svcs/v1/group/',
    groupNames: '/svcs/v1/group/names',
    aggregateReportByService: `/svcs/v2/report/sessions`,
    aggregateReportSingle: `/svcs/v2/report/singleGroup`, //add ?configTag={name of group}
    remoteConfig:'/svcs/v2/remoteConfig', //?configName={name of the Domain i.e. eg. domainsConfiguration}
    userAuthenticate: `/svcs/v1/user/login`,
    userChangePassword: `/svcs/v1/user/password`,
    userRefresh: `/svcs/v1/user/refresh`,
    sendVersion: `/ui-version/`,
    resendOnboardingNotification: `/svcs/v1/sms/onboarding/send`,
    updateProvAccount: `/svcs/v1/account/update/`,
}