'use client';

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IconLogout } from '@tabler/icons-react';
import NavLinks from "./nav-links";
import LogoSvgFunc from './logoSvg';
import "./sideStyles.css";

export default function SideNav() {
    const [isOpen, setIsOpen] = useState(false);
    const router = useNavigate();

    const handleClick = () => {
        router('/login');
        localStorage.removeItem("refreshData");
        sessionStorage.removeItem("authData");  
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <div className={`hamburger-menu ${isOpen ? 'open' : ''}`} onClick={toggleSidebar}>
                <div className="hamburger-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>

            <div className={`sidebar ${isOpen ? 'open' : ''} flex h-full flex-col px-3 py-4 md:px-2`} style={{
                backgroundColor: "rgb(var(--sidebar-background))",
                boxShadow: "0px, 10px, 20px, rgb(var(--shadow),0.5)",
                position: "relative",
                zIndex: 10,
            }}>
                <Link
                    className="mb-2"
                    style={{backgroundColor: "transparent", paddingLeft:"1rem" , paddingTop:"1rem" }}
                    to="/dashboard"
                >
                    <div className="w-32 md:w-40"
                    style={{color: "rgb(var(--header-text-color))"}}>
                        <LogoSvgFunc />
                    </div>
                </Link>
                <div className="flex grow flex-row justify-between space-x-2 md:flex-col md:space-x-0 md:space-y-2">
                    <NavLinks />
                    <div className="hidden h-auto w-full grow rounded-md md:block"
                    style={{color: "rgb(var(--accent-color2"}}></div>
                    <form>
                        <button className="flex h-[48px] w-full grow items-center justify-center gap-2 rounded-md bg-grey-50 p-3 text-sm font-medium hover:bg-sky-100 hover:text-blue-600 md:flex-none md:justify-start md:p-2 md:px-3" onClick={handleClick}>
                            <IconLogout className="w-6" />
                            <div className="hidden md:block">Logout</div>
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}
