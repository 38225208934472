import React, {useEffect, useState} from "react";
import {getDeviceCount} from "../../api/shared";
import { Pane } from "evergreen-ui";
import "./other.css"

export default function DeviceNum() {
  const [deviceCount, setDeviceCount] = useState<number>(0);

  useEffect(() => {
    getDeviceCount().then((count) => {
      setDeviceCount(count);
    });
  }, []);

  return (
    <div>
      <p className="device-count">{deviceCount}</p>
    </div>
  );
}