// import Image from 'next/image';
import React from 'react';
import LogoSvg from '../Logo.svg'

const LogoSvgFunc: React.FC = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
          }}>
            <img src={LogoSvg} alt="Logo" width={100} height={100}/>
        </div>
    );
};

export default LogoSvgFunc;