"use client";
import { useNavigate } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  RadialLinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import React, { Suspense, useState, useEffect } from "react";
import {
  Pane,
  Card,
  Heading,
  Text,
  SelectMenu,
  Button,
} from "evergreen-ui";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getGroups, getGroupData} from "../api/shared";
import BarChartComponent from "../ui/dashboard/barChart";
import PolarChartComponent from "../ui/dashboard/polarChart";
import LineGraphComponent from "../ui/dashboard/lineGraph";
import errorHandler from '../ui/errorMessages/errorHandler';
import DeviceNum from '../ui/dashboard/deviceNum';
import "./dashStyles.css";
import Layout from '../components/layout';
import MostUsedComponent from '../ui/dashboard/mostUsed';
import DataUsageComponent from '../ui/dashboard/dataUsed';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  RadialLinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const GroupList = React.lazy(() => import("../ui/dashboard/groupList"));

type GroupOption = {
  label: string;
  value: string;
};

export default function Page() {
  const [groups, setGroups] = useState<GroupOption[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  // const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [groupData, setGroupData] = useState<any>(null);
  const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().setMonth(new Date().getMonth() - 1)));
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const defaultGroup = groups.length > 0 ? groups[0] : "";
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const router = useNavigate();
  
  const setLast30Days = () => {
    setStartDate(new Date(new Date().setDate(new Date().getDate() - 30)));
    setEndDate(new Date());
  }

  const setLastSixMonths = () => {
    setStartDate(new Date(new Date().setMonth(new Date().getMonth() - 6)));
    setEndDate(new Date());
  }

  const setLastYear = () => {
    setStartDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
    setEndDate(new Date());
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const token = sessionStorage.getItem('authData');

      if (!token) {
        router('/login');
      } else {
      }
    }
  }, []);

  useEffect(() => {
    const loadGroups = async () => {
      try {
        const data = await getGroups();
        setGroups(data.map((group) => ({ label: group, value: group })));
      } catch (error) {
        errorHandler(error);
        console.error("Error getting groups", error);
      } finally {
        setLoading(false);
      }
    };
    loadGroups();
  }, []);

  useEffect(() => {
    const fetchGroupData = async () => {
      if (selectedGroup) {
        try {
          const data = await getGroupData(selectedGroup);
          setGroupData(data);
        } catch (error) {
          errorHandler(error);
          console.error("Error getting group data", error);
        }
      }
    };
    fetchGroupData();
  }, [selectedGroup]); // This will only trigger when a new group is selected

  return (
    <Layout>
      <Pane
        background="rgb(var(--background-start-rgb))"
        color="rgb(var(--forground-rgb))"
      >
        <Pane display="flex" justifyContent="space-between" marginBottom={16}>
          <Card
            elevation={1}
            background="rgb(var(--info-box-color))"
            padding={16}
            flex="1"
            marginRight={8}
          >
            <Heading size={600} marginBottom={8} color="rgb(var(--header-color))">
              Total Device Connections:
            </Heading>
            <Text size={400} color="rgb(var(--text-color))">
              <DeviceNum />
            </Text>
          </Card>
          <Card
            elevation={1}
            background="rgb(var(--info-box-color))"
            padding={16}
            flex="1"
            marginRight={8}
          >
            <Heading size={600} marginBottom={8} color="rgb(var(--header-color))">
              Most Used Service:
            </Heading>
            <Text size={400} color="rgb(var(--text-color))">
              <Suspense fallback={<div>Loading...</div>}>
                <MostUsedComponent groupData={groupData} selectedGroup={selectedGroup} startDate={startDate} endDate={endDate}/>
              </Suspense>
            </Text>
          </Card>
          <Card
            elevation={1}
            background="rgb(var(--info-box-color))"
            padding={16}
            flex="1"
          >
            <Heading size={600} marginBottom={8} color="rgb(var(--header-color))">
              Total Data Usage:
            </Heading>
            <Text size={400} color="rgb(var(--text-color))">
              <DataUsageComponent groupData={groupData} selectedGroup={selectedGroup} startDate={startDate} endDate={endDate}/>
            </Text>
          </Card>
        </Pane>

        <Pane
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={16}
        >
          <Pane display="flex" alignItems="center" marginLeft={16}>
            {/* ----------------------- Select Group ----------------------- */}
            <Text marginRight={8} style={{ color: "rgb(var(--text-color))" }}>
              Select a Group:
            </Text>
            <Suspense fallback={<div>Loading...</div>}>
              <SelectMenu
                title="Select Group"
                options={groups}
                selected={selectedGroup}
                closeOnSelect={true}
                onSelect={(item) => {
                  const selectedValue = String(item.value);
                  setSelectedGroup(selectedValue);
                }}
              >
                <Button
                  style={{
                    backgroundColor: "rgb(var(--sidebar-background))",
                    color: "rgb(var(--text-color))",
                    border: "2px solid rgb(var(--cust-border))",
                    borderRadius: "10px",
                    blockSize: "45px",
                  }}
                >
                  {selectedGroup || "Select Group"}
                </Button>
              </SelectMenu>
            </Suspense>
            <Pane display="flex" alignItems="center" marginLeft={16}>
              {/* ----------------------- Select Start Date ----------------------- */}
              <Text marginRight={8} style={{ color: "rgb(var(--text-color))" }}>
                Start Date:
              </Text>
              <Pane style={{ border: "20px soild rgb(var(--cust-border))" }}>
                <DatePicker
                  selected={startDate}
                  onChange={(date: Date | null) => {
                    setStartDate(date);
                  }}
                />
              </Pane>
              {/* ----------------------- Select End Date ----------------------- */}
              <Text marginRight={8} marginLeft={8} style={{ color: "rgb(var(--text-color))" }}>
                End Date:
              </Text>
              <DatePicker
                selected={endDate}
                onChange={(date: Date | null) => {
                  setEndDate(date);
                }}
              />
              <Pane display="flex" alignItems="center" marginLeft={16}>
                <Button
                  style={{
                    backgroundColor: "rgb(var(--sidebar-background))",
                    color: "rgb(var(--text-color))",
                    border: "2px solid rgb(var(--cust-border))",
                    borderRadius: "10px",
                    blockSize: "45px",
                  }}
                  onClick={setLast30Days}>Last 30 Days
                </Button>
              </Pane>
              <Pane display="flex" alignItems="center" marginLeft={16}>
                <Button
                  style={{
                    backgroundColor: "rgb(var(--sidebar-background))",
                    color: "rgb(var(--text-color))",
                    border: "2px solid rgb(var(--cust-border))",
                    borderRadius: "10px",
                    blockSize: "45px",
                  }}
                  onClick={setLastSixMonths}>Last 6 Months
                </Button>
              </Pane>
              <Pane display="flex" alignItems="center" marginLeft={16}>
                <Button
                  style={{
                    backgroundColor: "rgb(var(--sidebar-background))",
                    color: "rgb(var(--text-color))",
                    border: "2px solid rgb(var(--cust-border))",
                    borderRadius: "10px",
                    blockSize: "45px",
                  }}
                  onClick={setLastYear}>Last Year
                </Button>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
        <div className="grid-container">
          <div className="grid-item bar-chart">
            <Suspense fallback={<div></div>}> {/* These divs mean nothing and don't do too much but if something goes really wrong they keep things together */}
              <BarChartComponent groupData={groupData} selectedGroup={selectedGroup} startDate={startDate} endDate={endDate} />
            </Suspense>
          </div>
          <div className="grid-item pie-chart">
            <Suspense fallback={<div>Loading...</div>}>
              <PolarChartComponent groupData={groupData} selectedGroup={selectedGroup} startDate={startDate} endDate={endDate} />
            </Suspense>
          </div>
          <div className="grid-item line-chart">
            <Suspense fallback={<div>Loading...</div>}>
              <LineGraphComponent groupData={groupData} selectedGroup={selectedGroup} startDate={startDate} endDate={endDate} />
            </Suspense>
          </div>
        </div>
      </Pane>
    </Layout>
  );
}
