import React from "react";
import { toaster } from "evergreen-ui";

export const errorHandler = (error: any) => {
    if (error.response) {
        if (error.response.status === 401) {
            toaster.warning('Unauthorized', {description: 'Please login again'});
            return 'Unauthorized';
        }
        if (error.response.status === 403) {
            toaster.warning('Forbidden');
            return 'Forbidden';
        }
        if (error.response.status === 404) {
            toaster.warning('Not Found');
            return 'Not Found';
        }
        if (error.response.status === 500) {
            toaster.warning('Internal Server Error');
            return 'Internal Server Error';
        }
        if (error.response.status === 502) {
            toaster.warning('Bad Gateway');
            return 'Bad Gateway';
        }
        if (error.response.status === 503) {
            toaster.warning('Service Unavailable');
            return 'Service Unavailable';
        }
        if (error.response.status === 504) {
            toaster.warning('Gateway Timeout');
            return 'Gateway Timeout';
        }
    }
    toaster.danger('Unknown Error');
    return 'Unknown Error';
};

export default errorHandler;