// Desc: Main entry point for the application
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import LoginPage from './login';
import Dashboard from './dashboard';
import GroupPage from './groups';
import Accounts from './accounts';
import ChangePassword from './change-password';
import { GlobalAuthProvider } from './ui/logoutWarning/logoutWarning';

function App() {
  return (
    <>
      <Router>
        <GlobalAuthProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/groups" element={<GroupPage />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/change-password" element={<ChangePassword />} />
            {/* <Route path="/home" element={<Home />} /> Add other routes here */}
          </Routes>
        </GlobalAuthProvider>
      </Router>
    </>
  );
}

export default App;
