"use client";

import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
// import { useRouter } from 'next/navigation';
import styles from './login.module.css';
import { login } from '../api/shared';
import LogoSvgFunc from './logoSvg';
import { Suspense } from 'react';

const LoadingDots = () => {
  return (
    <div className={styles.loader}>
      <div className={styles.ball}></div>
      <div className={styles.ball}></div>
      <div className={styles.ball}></div>
    </div>
  );
};

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const router =  useNavigate();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        const response = await login(username, password);
        if (response && response.access_token && response.changePasswordIsRequired) {
            router('/change-password');
        } else if (response && response.access_token) {
            router('/dashboard');
        } else {
            alert('Login failed');
            setIsLoading(false);
        }
    };

  return (
    <div className={styles.container}>
      <div className={styles.loginCard}>
      <LogoSvgFunc />
      <h2 className={styles.heading}>Enterprise Console Portal</h2>
      <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.inputGroup}>
      <label className={styles.label} htmlFor="username">
            </label>
            <input
              className={styles.input}
              type="text"
              id="username"
              name="username"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.label} htmlFor="password">
            </label>
            <input
              className={styles.input}
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
      </div>
      <div className="button-wrapper">
        <button className="custom-button" type="submit" disabled={isLoading}>
          {isLoading ? <LoadingDots /> : 'Log In'}
        </button>
      </div>
    </form>
    </div>
    </div>
  );
};

export default LoginPage;